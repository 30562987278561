export function isSet(value){
    return !(value === null || value === undefined || value === '')
}

/**
 * Storeから取得してきたデータのうちKeysに含まれるものをsetterつきのcomputedデータとして扱えるよう変換する
 * @param {Array} keys
 */
export function mapComputedProperties(keys) {
    const map = []
    keys.forEach(key => {
        map[key] = {
            get(){
                return this.data[key]
            },
            set(newValue){
                this.update({
                    [key]:newValue
                })
            }
        }
    })
    return map
}