<template>
<temp-default>
    <div class="bl_wrapper bl_wrapper__2column">
        <div class="bl_form ly_form">
            <app-section>
                <h3 slot="h3">
                    <span>計算条件の入力</span>
                </h3>
                <app-box
                    :available="true"
                    label="地域区分" 
                    class="flex_column"
                >
                    <div
                        class="uq_flex__row"
                    >
                        <app-radio
                            v-for="number in [1,2,3,4]"
                            :key="'region' + (number)"
                            v-model="region"
                            @change="calc"
                            :value="(number).toString()"
                            name="region"
                        >
                            {{number}}地域
                        </app-radio>
                    </div>
                    <div
                        class="uq_flex__row"
                    >
                        <app-radio
                            v-for="number in [5,6,7]"
                            :key="'region' + (number)"
                            v-model="region"
                            @change="calc"
                            :value="(number).toString()"
                            name="region"
                        >
                            {{number}}地域
                        </app-radio>
                    </div>
                </app-box>
                <app-box
                    :available="true"
                    label="床の種類"
                    class="flex_column"
                >
                    <app-radio
                        v-model="type"
                        @change="calc"
                        value="1"
                        name="type"
                    >
                        床の下側に空間を持つ床
                    </app-radio>
                    <app-radio
                        v-model="type"
                        @change="calc"
                        value="2"
                        name="type"
                    >
                        床の下側に空間を持たない床(土間床)
                    </app-radio>
                </app-box>
                <app-box
                    :available="true"
                    label="床の熱貫流率（U値）" 
                    class="flex_column"
                    v-if="type != 2"
                >
                    <app-text
                        label=""
                        unit="W/m2K"
                        v-model="uValue"
                        @input="calc"
                        class="number"
                    />
                </app-box>
                <app-box
                    :available="true"
                    label="床パネル下の隣接空間等の種類" 
                    class="flex_column"
                    v-if="type != 2"
                >
                    <app-radio
                        v-model="underFloorPanel"
                        @change="calc"
                        value="1"
                        name="underFloorPanel"
                    >
                        外気、外気に通じる空間
                    </app-radio>
                    <app-radio
                        v-model="underFloorPanel"
                        @change="calc"
                        value="2"
                        name="underFloorPanel"
                    >
                        外気に通じていない空間、外気に通じる床裏
                    </app-radio>
                    <app-radio
                        v-model="underFloorPanel"
                        @change="calc"
                        value="3"
                        name="underFloorPanel"
                    >
                        住戸及び住戸と同様の熱的環境の空間、外気に通じていない床裏
                    </app-radio>

                </app-box>
            </app-section>
        </div>
        <div class="bl_result">
            <app-section>
                <h3 slot="h3">
                    <span>計算結果</span>
                </h3>
                <div
                    class="bl_resultMenu_inner ly_resultMenu_inner bl_resultMenu_inner__summary">
                    <div class="bl_resultMenu_inner_result ly_resultMenu_inner_result">
                        <div class="bl_resultMenu_inner_result_box">
                            <span class="bl_resultMenu_inner_result_box_title">
                                床暖房の上面放熱率
                            </span>
                            <span class="bl_resultMenu_inner_result_box_value">{{topHeatReleaseRate}}</span>%
                        </div>
                    </div>
                </div>
                <div class="bl_resultMenu_inner ly_resultMenu_inner bl_resultMenu_inner__process">
                    <h4 class="result_title">計算過程</h4>
                    <div class="bl_calcProcess">
                        <dl
                            v-if="type != 2"
                            class="bl_calcProcess_list"
                        >
                            <dt class="bl_calcProcess_list_data">
                                <span class="bl_calcProcess_list_data_header">R<sub>si</sub> + R<sub>su</sub></span>
                                <span class="bl_calcProcess_list_data_value">= {{rsiPlusRsu}} <small>[m<sup>2</sup>/W]</small></span>
                            </dt>
                        </dl>
                        <dl
                            v-if="type != 2"
                            class="bl_calcProcess_list"
                        >
                            <dt class="bl_calcProcess_list_data">
                                <span class="bl_calcProcess_list_data_header">H</span>
                                <span class="bl_calcProcess_list_data_value">= {{hValue}}</span>
                            </dt>
                        </dl>
                        <dl
                            v-if="type != 2"
                            class="bl_calcProcess_list"
                        >
                            <dt class="bl_calcProcess_list_data">
                                <span class="bl_calcProcess_list_data_header">R<sub>si</sub> + R<sub>U</sub> + R_<sub>P</sub> + R<sub>D</sub> + R<sub>se</sub></span>
                                <span class="bl_calcProcess_list_data_value">= {{rSiPlusRuPlusRpPlusRdPlusRse.toFixed(3)}} <small>[m<sup>2</sup>/W]</small></span>
                            </dt>
                        </dl>
                        <dl class="bl_calcProcess_list">
                            <dt class="bl_calcProcess_list_data">
                                <span class="bl_calcProcess_list_data_header">r<sub>up,H</sub></span>
                                <span class="bl_calcProcess_list_data_value">= {{rupAndH.toFixed(3)}}</span>
                            </dt>
                        </dl>
                    </div>
                </div>
            </app-section>
        </div>
    </div>
    <p class="bl_msg__center">届出等に使用する場合は、この画面を印刷します。</p>
</temp-default>
</template>

<script>
import TempDefault from '@/components/templates/TempDefault.vue'
import { mapComputedProperties } from '@/util'
import { mapGetters, mapActions } from 'vuex'

export default {
    name: 'AppForm',
    components: {
        TempDefault
    },
    computed:{
        ...mapGetters('calc', {
            data:'calcData',
        }),
        ...mapComputedProperties([
            'region',
            'type',
            'uValue',
            'underFloorPanel',
            'topHeatReleaseRate',
            'rsiPlusRsu',
            'hValue',
            'rSiPlusRuPlusRpPlusRdPlusRse',
            'rupAndH',
        ])
    },
    mounted: function () {
        window.addEventListener('resize', this.handleResize)
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.handleResize)
    },
    methods: {
        ...mapActions('calc', [
            'update',
            'calc',
        ]),
        toResult(){
            this.$router.push('/result')
        },
        handleResize() {
            // ウインドウサイズがリサイズされたとき、ウインドウサイズを取得
            this.width = window.innerWidth;
            this.height = window.innerHeight;
        }
    }
}
</script>

<style scoped>
.app_box {
    grid-template-columns: 14em 1fr;
    -ms-grid-columns: 14em 1fr;
}
.bl_wrapper__2column{
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}
.bl_wrapper__2column>div{
    width: 49%;
    min-width: 400px;
}

.bl_wrapper__2column >>> .app_section{
    width: 100%;
    min-width: 300px;
    margin: 0 auto;
}
.uq_flex__row{
    display: flex;
    flex-direction: row;
}
.section_wrap h3>span{
   padding-top: 0; 
}
/* 計算結果 */
.bl_result >>> .app_section{
    padding: 16px;
    background-color: rgba(83, 191, 216, 0.1);
}
.ly_resultMenu_inner{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
    padding: 0 8px 8px;
}
.bl_resultMenu_inner__summary{
    width: 100%;
    margin: 0 auto;
}
.bl_resultMenu_inner__summary .result_title{
    width: 100%;
}
.bl_resultMenu_inner__summary .bl_resultMenu_inner_result{
    width: 100%;
}
.bl_resultMenu_inner_result_box_title{
    margin-right: 16px;
}
.bl_resultMenu_inner_result{
    background-color: white;
    border: 2px solid #53BFD8;
    border-radius: 8px;
}
.ly_resultMenu_inner_result{
    display: flex;
    flex-direction: column;
    padding: 8px;
}
.bl_resultMenu_inner_result_box{
    padding: 4px 16px;
}

.bl_resultMenu_inner_result {
    margin: 0 auto 50px;
}
.result_title,
.bl_resultMenu_inner_result_box{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    font-size: 16px;
    font-weight: bolder;
}
.bl_resultMenu_inner_result_box_value{
    margin-left: 8px;
    font-size: 24px;
}
_:-ms-lang(x)::-ms-backdrop, .ly_bottomMenu_inner{
    justify-content: center;
}
_:-ms-lang(x)::-ms-backdrop, .ly_bottomMenu_inner .result_title{
    margin-right: 8px;
}
.bl_calcProcess{
    padding: 8px;
    background-color: white;
    border-radius: 8px;

}
.bl_resultMenu_inner__process{
    width: 100%;
}
.bl_resultMenu_inner__process .result_title{
    display: flex;
    justify-content: flex-start;
    width: 100%;
    text-align: left;
}
.bl_calcProcess_list{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 4px 16px;
}
.bl_calcProcess_list:not(:last-child){
    border-bottom: 1px solid #53BFD8;
}
.bl_calcProcess_list_data{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-width: 30em;
}
.bl_calcProcess_list_data_header{
    min-width: 15em;
    font-size: 14px;
    font-weight: bolder;
}
.bl_calcProcess_list_data_value{
    min-width: 5em;
    margin-left: 8px;
    font-size: 16px;
    line-height: 16px;
}
.bl_calcProcess_list_data_value small{
    font-size: 12px;
}
.bl_calcProcess_list_formula{
    display: block;
    width: fit-content;
}
.bl_calcProcess_list_formula a img{
    max-width: calc(850px - 32em);
}
.btn_pdf{
    padding: 8px 50px;
    font-size: 18px;
    background: linear-gradient(#6dc7dd, #41b8d4);
    border-radius: 8px;
}
.btn_toForm{
    margin-top: 20px;
}
.bl_msg__center{
    width: 100%;
    text-align: center;
}
/* レスポンシブ（タブレット縦よりウインドウ幅が狭いもの） */
@media screen and (max-width: 1000px) {
    .ly_resultMenu{
        left: calc(50vw - 50% + 30px);
    }
    .ly_resultMenu_inner{
        flex-wrap: wrap;
    }
    .bl_resultMenu_inner__summary .bl_resultMenu_inner_result{
        width: 100%;
    }
    .bl_resultMenu_inner_result_box{
        flex-direction: column;
    }
    .bl_resultMenu_inner_result_box_title{
        width: 100%;
        text-align: center;
    }
    .bl_calcProcess_list_data{
        min-width: 100%;
    }
    .bl_calcProcess_list_data_header{
        min-width: fit-content;
    }
}
</style>